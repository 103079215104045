import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const TrainingPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>FishmedNet Training</h2>
      <p>
        Within the framework of the activities carried out by the FISHMEDNET project, training represents a key tool to improve the skills of companies and develop new opportunities for diversifying fishing activities.
      </p>
      <p>
        Through 4 training modules, each dedicated to a specific theme, the interviewed experts outline the challenges and opportunities of diversification activities while also providing testimonies of good practices already present in the Mediterranean context.
      </p>
      <p>
        <a href="https://www.youtube.com/@fishmednetenicbcmed/playlists">https://www.youtube.com/@fishmednetenicbcmed/playlists</a>
      </p>

      <h3>Tourism</h3>
      <br/>
      <strong>From itti-tourism to fishing-tourism, all the necessary steps to start a new activity</strong>
      <p>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/NciIgW1V7IQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>

      <h3>Aquaculture</h3>
      <br/>
      <strong>What are the opportunities and challenges in the aquaculture sector, and how to start and manage an aquaculture business?</strong>
      <p>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/qDfG1UxbSQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>

      <h3>Food</h3>
      <br/>
      <strong>An introduction to the main processes of fish product transformation and the associated risks</strong>
      <p>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/uUa_jNEdzkY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>

      <h3>Community Services</h3>
      <br/>
      <strong>An overview of the various environmental services related to the diversification of fishing</strong>
      <p>
        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/6j9i55HSvow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </p>

      <h2>Esplora tra le ultime proposte</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default TrainingPage
